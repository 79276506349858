// * SESSION SAGA
// * ------------
// This file contains generator functions (sagas), which handle complex asynchronous workflows.
// This includes making API calls using the axios service defined in apiClient.js and also
// side effects (i.e. dispatching Redux actions) based on the outcomes of those API calls.
// Please note that sagas should not import directly from the Redux store.
// Instead, they should:
// - Take from the action payload
// - Make use of "put" to dispatch actions
// - Make use of effects (i.e. call) for API calls
// - Make use of "select" to access the Redux store

// * LIBRARY/FRAMEWORK IMPORTS
import { call, put, takeLeading } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// * LOCAL IMPORTS
import { api } from '../../api/axiosClient';
import { decodeCookie, csrfCookie } from '../utils/checkCookies';
// * SLICE IMPORTS
import { authActions } from '../authSlice';

// * SESSION SAGA LOGIC
// * ------------------
// This global generator function is responsible for verifying the user's authentication state
// by contacting the backend just when the app initializes (before any routes or components are
// rendered) and also when the app is refreshed/reloads.

function* sessionSaga() {
  try {
    // Check for existance of cookie with name "time_meta_cookie", "shield_access_cookie",
    // and "shield_refresh_cookie". Store their values in the varibles.
    const timeMetaCookieValue = decodeCookie('time_meta_cookie');
    const csrfCookieAccessValue = csrfCookie('access');
    const csrfCookieRefreshValue = csrfCookie('refresh');

    // If all 3 of these cookies exist, begin real authentication check
    // by sending access token to endpoint.
    if (
      timeMetaCookieValue &&
      csrfCookieAccessValue &&
      csrfCookieRefreshValue
    ) {
      // If request successful, it means user's access token is valid.
      // Thus this session has been authenticated. The return response
      // should contain a response object (response.data).
      const response = yield call(api.get, '/auth/session');

      // If response object is present, dispatch action indicating
      // session success to store and print success message.
      if (response.data.session) {
        yield put(authActions.sessionSuccess(response.data));
        console.info('Session successfully validated: ', response.data);

        // Since the session has been authenticated, dispatch action that's
        // currently being listened to by authMiddleware.js. Note: setTimeout,
        // which is used in scheduleRefresh.js, cannot be used here in a saga;
        // so don't even think logic from there can simply be moved into here.
        yield put(authActions.refreshTimer(response.data));

        // If, for whatever reason, the response object is missing,
        // print fail message and push user to sign-in page.
      } else {
        yield put(authActions.sessionFailure(response.data));
        yield put(push('/sign-in'));
        console.error('Session failed validation: ', response.data);
      }
    } else {
      // If any of the 3 cookies do not exist in browser, it's likely due to the fact
      // that the user public user and no authenticaiton is required.
      console.info('Missing cookies: No session validation required');
    }
  } catch (error) {
    // If error occurs in try block, which can happen for a variety of reasons,
    // make an attempt to catch HTTP and NON-HTTP errors.
    let curatedError = 'Error during session validation';

    // HTTP ERRORS
    // These errors can result from bad API request, expired/invalid tokens, etc.
    // They can have two possible response objects, which are typical of HTTP errors
    // thrown by axios: response and response.data. If so, this condition will isolate
    // such an error message from response.data.message.
    if (error.response && error.response.data) {
      curatedError = error.response.data.message || curatedError;

      // NON-HTTP ERRORS
      // These errors can result from network problems, JavaScript errors, misconfigured
      // axiosClient.js, etc. They do not have a response object, which is an indication
      // that it's not an HTTP error. If so, the error's message property will be used
      // to isolte the error message.
    } else if (error.message) {
      curatedError = error.message;
    }
    // Finally, pass the properly isolated error message to the action that will
    // be dispatched to the redux store, redirect the user to the sign-in page,
    // log this error to console.
    yield put(authActions.sessionFailure(curatedError));
    yield put(push('/sign-in'));
    console.error('Error during session validation:', curatedError);
  }
}

// * WATCHER SAGA
// * ------------
// This "watchSessionSaga" function is configured into the Redux Saga's middleware,
// which continuously listens for "authActions.sessionStart" action to be trigerred.
// This action is triggered by "useEffect" in authProvider.js, which provides a Context
// API wrapper around the App. After the trigger, this "SessionSaga" is called, which then
// takes care of all the logic. Note: "takeLeading" is used (instead of takeLatest) because,
// once the first (leading) operation starts, we don't want to potentially interrupt it with
// the same multiple concurrent operations.
export function* watchSessionSaga() {
  yield takeLeading(authActions.sessionStart.type, sessionSaga);
}
