// * AUTH SLICE
// * ----------
// Redux Tookit's "createSlice" function is being used here to consolidate the logic
// for actions and reducers into a single module.

// ESLINT NOTE
// Redux Toolkit's "createSlice" function uses an internal "Immer" library to write
// "mutating" logic in reducers. This logic produces a new state based on the modified
// draft state. Unfortunately, ESLint doesn't understand this mutation is safe. So this
// ESLint rule for "no-aram-reassign" is being disabled here manually in each Reduc Tookit
// slice. We still need this rule globally to enforce checks on mutating logic.
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// * DEFINE INITIAL STATE
// * --------------------
// This defines the initial state structure of the slice. It's used directly inside the
// createSlice object(below) in two different locations: once as part of the standard
// initial definition and a second time to create reset action.
const initialState = {
  // ACCESS STATE (GATEWAY)
  gateway: false,
  // AUTHENTICATION STATE
  session: false,
  // AUTHENTICATION STATUS
  // Possible states: 'idle' (no operation started) | 'loading' | 'succeeded' | 'failed'
  status: 'idle',
  // TYPE (OF USER)
  // Possible states: 'provider' | 'patient'
  user: null,
  error: null,
};

// * CREATE SLICE LOGIC
// * ------------------
// CALL CREATESLICE
// This function encapsulates reducers and actions. It defines the slice name as "signIn",
// the initialState (defined above), and the reducers. This function is special because
// it will automatically, based on the reducers, generate actions creators & types.
const authSlice = createSlice({
  name: 'auth',
  initialState,
  // Each method inside this reducer represents a "case reducer" and each one
  // defines how the app's state should change (in response to a dispatched action).
  // For this slice, the app's state should change as a result of the following actions:
  // SIGN-IN
  // - sign-in initiation
  // SIGN-OUT
  // -
  reducers: {
    // * SIGN-IN DOMAIN
    // Upon action dispatch, this will be the state change.
    signInStart: state => {
      state.status = 'loading';
      state.error = null;
    },
    // Upon action dispatch and a response object return with status code 200, this will be
    // the state change. Note: the return response object will not only contain JWT tokens
    // in HttpOnly cookies but also user information (action.payload).
    signInSuccess: state => {
      state.session = true;
      state.status = 'succeeded';
      state.user = 'provider';
      state.error = null;
    },
    // Upon action dispatch and a response object return with status code !200, this will be
    // the state change. Note: the return response object will contain error information
    // (action.payload) that should be displayed to the client.
    signInFailure: (state, action) => {
      state.session = false;
      state.status = 'failed';
      state.user = null;
      state.error = action.payload;
    },

    // * SIGN-OUT DOMAIN
    // Upon action dispatch, this will be the state change.
    signOutStart: state => {
      state.status = 'loading';
      state.error = null;
    },
    // Upon action dispatch and return of a response object with a status code 200, this will be
    // the state change. On the backend, the server "signs-out" users by placing their token in
    // the RevokedToken table. And on the frontend, sensitive provider data is cleared out from
    // the state by setting it "null". Note: this is actually done by "resetProvider" action
    // imported into signOutSaga.js.
    signOutSuccess: state => {
      state.session = false;
      state.status = 'succeeded';
      state.user = null;
      state.error = null;
    },
    // Upon action dispatch and a response object return with status code !200, this will be
    // the state change. Note: the return response object will contain error information
    // (action.payload) that should be displayed to the client.
    signOutFailure: (state, action) => {
      state.status = 'failed';
      state.user = null;
      state.error = action.payload;
    },

    // * SESSION DOMAIN
    sessionStart: state => {
      state.status = 'loading';
      state.error = null;
    },
    sessionSuccess: state => {
      state.session = true;
      state.status = 'succeeded';
      state.user = 'provider';
      state.error = null;
    },
    sessionFailure: (state, action) => {
      state.session = false;
      state.status = 'failed';
      state.user = null;
      state.error = action.payload;
    },

    // * REFRESH DOMAIN
    refreshTimer: state => {
      state.status = 'countdown';
      state.error = null;
    },
    refreshStart: state => {
      state.status = 'loading';
      state.error = null;
    },
    refreshSuccess: state => {
      state.session = true;
      state.status = 'succeeded';
      state.user = 'provider';
      state.error = null;
    },
    refreshFailure: (state, action) => {
      state.session = false;
      state.status = 'failed';
      state.error = action.payload;
    },

    // * ACCESS DOMAIN
    // Existing reducers
    setGateway: (state, action) => {
      state.gateway = action.payload;
    },

    // * RESET STATE
    resetState: () => initialState,
  },
});

// EXPORT ACTION CREATORS
// This will be used throughout the app.
export const authActions = authSlice.actions;

// EXPORT REDUCER FUNCTION
// This reducer is given the name "authReducer". When actions are dispatched,
// it alone will  be used to handle updates to the  app's state.
export const authReducer = authSlice.reducer;
