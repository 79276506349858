// *LIBRARY/FRAMEWORK IMPORTS
// Add scroll event listener with the following hooks:
// useState: Add local state (for tracking scrolling, toggling darkMode, etc.)
// useEffect: Add side effects handling (to manage event listeners separate from render)
import React, { useState, useEffect } from 'react';
// Front-end routing (provision of Link element)
import { NavLink } from 'react-router-dom';
// Language translation (via `t` function)
import { useTranslation } from 'react-i18next';

// *FILE IMPORTS
// Dynamic logo
import { Logo } from '../../../shared/ui/layouts/header/HeaderLogo';
// Theme selector button
import { SelectorTheme } from '../../../shared/ui/layouts/header/HeaderSelectorTheme';
// Language translator button
import { SelectorLanguage } from '../../../shared/ui/layouts/header/HeaderSelectorLanguage';
import 'flag-icons/css/flag-icons.min.css';

// *DEFINE HEADER
// Pass props (darkMode and setDarkMode) from props in App.js
function PublicHeader() {
  // Declare state variable (thanks to useState): isScrolled with argument set to "false"
  // Declare state-setting function (to update state variable): setIsScrolled
  const [isScrolled, setIsScrolled] = useState(false);
  // Declare t function by calling useTranslation
  const { t } = useTranslation();

  // Use "useEffect" to run side effects outside the component rendering
  useEffect(() => {
    const handleScroll = () => {
      // If page scrolled vertically more than 10 pixels, setIsScrolled to "true"
      setIsScrolled(window.scrollY > 5);
    };

    // Attach "handleScroll" to the scroll event.
    window.addEventListener('scroll', handleScroll);

    // Cleanup Mechanism: Remove event listener when component umounts  (prevent memory leaks, etc.)
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // RETURN JSX
  return (
    <header className="h-28">
      <nav
        className={`fixed flex w-5/6 mt-4 py-2 px-4 bg-white items-center mx-auto
                    rounded-lg border border-gray-100 transition-shadow duration-500
                    justify-between left-0 right-0 z-10 
                     ${
                       isScrolled
                         ? 'shadow-lg shadow-blue-200'
                         : 'shadow-none'
                     }`}
      >
        <ul className="flex gap-4">
          <li className="flex mr-5">
            <NavLink to="/">
              <Logo />
            </NavLink>
          </li>

          <li className="flex items-center">
            <NavLink
              to="/how-it-works"
              className={({ isActive }) =>
                isActive
                  ? 'border border-gray-100 bg-gray-50 rounded-md shadow-inner text-black-100 px-3 py-2 outline-none'
                  : 'border border-transparent text-black-100 px-3 py-2 outline-none hover:rounded-md hover:bg-gray-50 hover:shadow-inner hover:text-gray-500 focus:rounded-md hover:border-gray-100 focus:bg-gray-50 focus:shadow-inner focus:outline-none'
              }
            >
              {t('Header.1U')}
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to="/secure-design"
              className={({ isActive }) =>
                isActive
                  ? 'border border-gray-100 bg-gray-50 rounded-md shadow-inner text-black-100 px-3 py-2 outline-none'
                  : 'border border-transparent text-black-100 px-3 py-2 outline-none hover:rounded-md hover:bg-gray-50 hover:shadow-inner hover:text-gray-500 focus:rounded-md hover:border-gray-100 focus:bg-gray-50 focus:shadow-inner focus:outline-none'
              }
            >
              {t('Header.2U')}
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to="/try-me"
              className={({ isActive }) =>
                isActive
                  ? 'border border-gray-100 bg-gray-50 rounded-md shadow-inner text-black-100 px-3 py-2 outline-none'
                  : 'border border-transparent text-black-100 px-3 py-2 outline-none hover:rounded-md hover:bg-gray-50 hover:shadow-inner hover:text-gray-500 focus:rounded-md hover:border-gray-100 focus:bg-gray-50 focus:shadow-inner focus:outline-none'
              }
            >
              {t('Header.3U')}
            </NavLink>
          </li>
        </ul>
        <ul className="flex gap-4">
          <li className="flex items-center">
            <SelectorTheme />
          </li>
          <li className="flex items-center">
            <SelectorLanguage />
          </li>

          <li className="flex items-center">
            <NavLink
              to="/sign-in"
              className="border border-gray-200 rounded-md px-3 py-2
                         hover:rounded-md hover:border-gray-100 hover:bg-gray-50 hover:shadow-inner hover:text-gray-500
                         focus:rounded-md focus:bg-gray-50 focus:shadow-inner focus:outline-none"
            >
              {t('Header.4U')}
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to="/register"
              className="border border-transparent bg-blue-500 rounded-md px-3 py-2 text-white
                         hover:border hover:border-blue-400 hover:bg-blue-400 hover:shadow-inner  
                         focus:border focus:border-blue-400 focus:bg-blue-400 focus:shadow-inner focus:outline-none"
            >
              {t('Header.5U')}
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export { PublicHeader };
