// * RTK QUERY API SERVICE
// * ---------------------
// This is the conf file for Redux Toolkit's (RTK) Query. It's used for data fetching and
// state management.Specifically, it has built-in mechanisms for caching data, invalidating
// cache (when data changes), deduplication of data requests, etc. It's integrated with Axios'
// advanced HTTP capabilities (i.e. interceptors, header manipulation, etc).

// * LIBRARY/FRAMEWORK IMPORTS
import { createApi } from '@reduxjs/toolkit/query/react';

// * LOCAL IMPORT - AXIOS CLIENT
import { api } from './axiosClient';

// * AXIOS ADAPTER (INTEGRATION WITH AXIOS)
// * -------------
// This is a specialized helper function that integrates Axios with RTK Query.
// As a result, all data fetching operations carried out by RTK Query are
// utilized using the Axios configuration.
const axiosBaseQuery =
  // This is the async function that will
  // take the object with various arguments.
  async ({ url, method, data, params }) => {
    try {
      // * AXIOS REQUESTS
      // Construct asynchronous request using Axios' configuration.
      // This is important because all requests need to have the
      // correct tokens attached in their headers, etc.
      console.info(
        `RTK Query - Request using ${method} method sent to ${url}`
      );
      const result = await api({
        url,
        method,
        data,
        params,
      });

      // * AXIOS RESPONSES
      // A successful response returns an object ("result") with a "data" property,
      // which is the actual response data. It's important this data format is captured
      // correctly because the "baseQuery" function (below) will automatically manage it
      // using cache and state management logic.
      console.info(`RTK Query - Response from ${url}: `, result);
      return { data: result.data };

      // * AXIOS ERRORS
      // This transforms Axios errors into a format expected by RTK Query,
      // ensuring errors can be properly propagated through RTK Query's
      // hooks and components.
    } catch (axiosError) {
      // Since request has failed, access the url property of the
      // "config" object (possibly undefined) and log to console.
      console.log(
        'RTK Query - URL of failed request:',
        axiosError.config?.url
      );
      // Check if error has a response property. Presence of this property
      // is indication server responded with a status code outside of 2xx.
      if (axiosError.response) {
        console.error(
          'RTK Query - Axios response error:',
          axiosError.response
        );
        // Check if error has request property. Presence of this property
        // is indication that no response was received from the server.
        // This typically happens due to network errors.
      } else if (axiosError.request) {
        console.error(
          'RTK Query - Axios Request error:',
          axiosError.request
        );
        // Check if error has message property. Presence of this property
        // is indication of Axios misconfiguration rather than network
        // or server error. An error like this will prevent requests
        // from going out in the first place.
      } else {
        console.error(
          'RTK Query - Axios setup error:',
          axiosError.message
        );
      }
      return {
        error: {
          status: axiosError.response?.status,
          statusText: axiosError.response?.statusText,
          data: axiosError.response?.data,
        },
      };
    }
  };

// * CREATE RTK QUERY API SLICE
// * --------------------------
// This Create the RTK Query API slice
export const apiSlice = createApi({
  // This is the key under which the reducer, generated bu this API slice,
  // will be mounted in the redux store.
  reducerPath: 'api',
  // Enable React Suspense
  useSuspense: true,
  // When sending requests, make use of Axios. This is possible thanks to
  // the "axiosBaseQuery" adapter (above).
  baseQuery: axiosBaseQuery,

  // These endpoints further leverage Axios client through "axiosBaseQuery" adapter (above).
  // Each endpoint is assocaited with a specific API operation (i.e. fetch user settings)
  // and is defined using "builders". These "builders" specify how to make requests and
  // process the response (including operations on the server).
  endpoints: builder => ({
    // * SLUG SECTION - GET JWT TOKEN
    openGateway: builder.mutation({
      query: credentials => ({
        url: '/auth/gateway',
        method: 'POST',
        data: credentials,
      }),
    }),

    // * SLUG SECTION - GET JWT TOKEN
    // Assuming your API expects a slug to fetch specific chat data
    validateSlug: builder.mutation({
      query: slug => ({
        url: `/patient/${slug}`,
        method: 'GET',
      }),
    }),
    // * CHAT SECTION - GET JWT TOKEN
    chatWithAI: builder.mutation({
      query: ({ message }) => ({
        url: `/patient/chat`,
        method: 'POST',
        data: { message },
      }),
    }),
    // * ACCOUNT SECTION - GET DATA
    // This endpoint fetchs QR Code data for a specific ID. It uses the "query" method
    // that takes and id and returns an object that will be used in the request.
    getAccountData: builder.query({
      query: () => ({
        url: '/provider/account',
        method: 'GET',
        keepUnusedDataFor: 5,
      }),
    }),
    // * ACCOUNT SECTION - PUT DATA
    updateAccountData: builder.mutation({
      query: account => ({
        url: `/provider/account`,
        method: 'PUT',
        data: account, // the payload for the update
      }),
      // Optionally, invalidate the cache for getAccountData after an update
      // to ensure the app has the latest data
      invalidatesTags: ['Account'],
    }),
    // * PATIENT SECTION - DATA
    getPatientsData: builder.query({
      // Now accepts an object with filtering, pagination, and sorting parameters
      query: ({
        pageIndex = 0,
        pageSize = 10,
        _filters,
        _globalFilter,
        _sorting,
      }) => {
        // Construct the URL with query parameters based on the input
        const params = new URLSearchParams({
          // Assuming your backend expects these parameters for pagination
          _start: pageIndex * pageSize,
          _limit: pageSize,
          // Add additional logic to convert filters and sorting to your API's expected format
          // For example:
          // filters: JSON.stringify(filters),
          // globalFilter: globalFilter,
          // sorting: JSON.stringify(sorting),
        });

        // Adjust the URL to your actual API endpoint for fetching patient data
        return {
          url: `/provider/patients?${params.toString()}`,
          method: 'GET',
        };
      },
      // Add transformers or response handlers as needed
    }),
    getDashboardData: builder.query({
      query: () => ({
        url: '/provider/dashboard',
        method: 'GET',
      }),
    }),
  }),
});

// REACT HOOKS
// These React hooks are automatically generated for each endpoint. They encapsulate the logic
// for making requests, caching the response, and managing loading/error states. Any component
// can use these hooks to trigger corresponding API calls, accces their status, data, and
// error information.
export const {
  useOpenGatewayMutation,
  useValidateSlugMutation,
  useChatWithAIMutation,
  useGetAccountDataQuery,
  useUpdateAccountDataMutation,
  useGetPatientsDataQuery,
  useGetDashboardDataQuery,
} = apiSlice;
