// * APP ENTRY POINT (ROOT COMPONENT)
// * ---------------
// This file is essentially the top-level component that contains the layout structure that
// gets rendered by index.js. It has application-level concerns: i.e. returns JSX, renders logic,
// performs authentication checks, etc. Also, this file has a component lifecycle through hooks
// (i.e.useEffect), which effects rendering of routes or components (based on authentication sate).

// * LIBRARY/FRAMEWORK IMPORTS
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// * LOCAL IMPORTS - COMPONENTS
import { ThemeProvider } from './state/context/ThemeProvider';
import { GlobalSpinner } from '../shared/ui/components/GlobalSpinner';
import { GatewayPage } from './GatewayPage'; // ! REMOVE FOR OFFICIAL DEPLOYMENT
import { useAuth } from './auth/authProvider'; // ! REMOVE FOR OFFICIAL DEPLOYMENT
// * LOCAL IMPORTS - LAYOUT
import { Header } from '../shared/ui/layouts/header/Header';
import { Footer } from '../shared/ui/layouts/footer/Footer';
import { HelloView } from '../patient/ui/views/Hello/HelloView';

// * LOCAL IMPORTS - CSS
import 'react-toastify/dist/ReactToastify.css';

// * LOCAL IMPORTS - GROUP LEVEL ROUTES
// ! NOTE: REACT LAZY LOADING REQUIRES THE IMPORT OF DEFAULT EXPORTS
// ! (INSTEAD OF NAMED EXPORTS). THE LATTER WILL NOT WORK.
const PublicRoutes = lazy(() => import('./routes/PublicRoutes'));
const ProviderRoutes = lazy(() => import('./routes/ProviderRoutes'));

// * RENDER JSX
// * ----------
export function App() {
  // State to store the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { openedGateway } = useAuth(); // ! REMOVE FOR OFFICIAL DEPLOYMENT

  // Effect to update the window width state on window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Determine if the header and footer should be rendered based on window width
  const shouldRender = windowWidth >= 720; // md breakpoint

  // Check if the user is authenticated, if not, render LoginPage
  if (!openedGateway) {
    return <GatewayPage />;
  }

  return (
    <ThemeProvider>
      <Suspense fallback={<GlobalSpinner />}>
        <div className="flex flex-col min-h-screen dark:bg-mac-550 dark:text-mac-20">
          {shouldRender && <Header />}
          <div className="flex flex-grow mx-auto w-5/6">
            <Routes>
              <Route path="/*" element={<PublicRoutes />} />
              <Route path="/provider/*" element={<ProviderRoutes />} />
              <Route path="/hello/:slug" element={<HelloView />} />
            </Routes>
          </div>
          {shouldRender && <Footer />}
        </div>
      </Suspense>
    </ThemeProvider>
  );
}
