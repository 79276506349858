// * REFRESH SAGA
// * ------------
// This file contains generator functions (sagas), which handle complex asynchronous workflows.
// This includes making API calls using the axios service defined in apiClient.js and also
// side effects (i.e. dispatching Redux actions) based on the outcomes of those API calls.
// Please note that Sagas should not import directly from the Redux store.
// Instead, they should:
// - Take from the action payload
// - Make use of "put" to dispatch actions
// - Make use of effects (i.e.call) for API calls
// - Make use of "select" to access the Redux store

// * LIBRARY/FRAMEWORK IMPORTS
import { call, put, takeLeading } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// * LOCAL IMPORTS
import { api } from '../../api/axiosClient';
import { decodeCookie, csrfCookie } from '../utils/checkCookies';
// * SLICE
import { authActions } from '../authSlice';

// * REFRESH SAGA LOGIC
// * ------------------
// This global gnerator is responsible for renewing/refreshing the access token. It checks the
// time_meta_cookie for the expiry time of the user's access token. If the access token is
// about to expire, it sends a refresh token to an API endpoint in order to renew the
// access token and update state.

function* refreshSaga() {
  try {
    // Check for existance of cookie with name "time_meta_cookie", "shield_access_cookie",
    // and "shield_refresh_cookie". Store their values in the varibles.
    const timeMetaCookieValue = decodeCookie('time_meta_cookie');
    const csrfCookieAccessValue = csrfCookie('access');
    const csrfCookieRefreshValue = csrfCookie('refresh');

    // If all 3 of these cookies exist, begin real authentication check
    // by sending access token to endpoint.
    if (
      timeMetaCookieValue &&
      csrfCookieAccessValue &&
      csrfCookieRefreshValue
    ) {
      // If request successful, it means user's refresh token is valid.
      // Thus the user should now have a new access token they can use.
      const response = yield call(api.post, '/auth/refresh');

      // If response object is present, dispatch success action to store
      // and print success message.
      if (response.data.session) {
        yield put(authActions.signInSuccess(response.data));
        console.info('New access token issued:', response.data);

        // Since a new access token has been issued, dispatch action that's
        // currently being listened to by authMiddleware.js. It will again,
        // repeat this cycle, by calling scheduleRefresh.js, which in turn
        // will calculate the next time to trigger this file. In doing so,
        // the access token will be refreshed again.
        yield put(authActions.refreshTimer(response.data));

        // If, for whatever reason, the response object is missing,
        // print fail message and push user to sign-in page.
      } else {
        yield put(authActions.signInFailure(response.data));
        yield put(push('/sign-in'));
        console.error('Failed to get new access token: ', response.data);
      }
    } else {
      // If any of the 3 cookies do not exist in browser, it's likely
      // because the user is a public user and no authenticaiton is required.
      console.info('Missing cookies: No session validation required');
    }
  } catch (error) {
    // If error occurs in try block, which can happen for a variety of reasons,
    // make an attempt to catch HTTP and NON-HTTP errors.
    let curatedError = 'Error during token refresh';

    // HTTP ERRORS
    // These errors can result from bad API request, expired/invalid tokens, etc.
    // They can have two possible response objects, which are typical of HTTP errors
    // thrown by axios: response and response.data. If so, this condition will isolate
    // such an error message from response.data.message.
    if (error.response && error.response.data) {
      curatedError = error.response.data.message || curatedError;

      // NON-HTTP ERRORS
      // These errors can result from network problems, JavaScript errors, misconfigured
      // axiosClient.js, etc. They do not have a response object, which is an indication
      // that it's not an HTTP error. If so, the error's message property will be used
      // to isolte the error message.
    } else if (error.message) {
      curatedError = error.message;
    }
    // Finally, pass the properly isolated error message to the action that will
    // be dispatched to the redux store, redirect the user to the sign-in page,
    // log this error to console.
    yield put(authActions.sessionFailure(curatedError));
    yield put(push('/sign-in'));
    console.error('Error during token refresh:', curatedError);
  }
}

// * WATCHER SAGA
// * ------------
// This "watchRefreshSaga" function is configured into the Redux Saga's middleware,
// which continuously listens for "authActions.signInStart" action to be trigerred.
// This action is triggered by "useEffect" in authProvider.js, which provides a Context
// API wrapper around the App. After the trigger, this "SessionSaga" is called, which then
// takes care of all the logic. Note: "takeLeading" is used (instead of takeLatest) because,
// once the first (leading) operation starts, we don't want to potentially interrupt it with
// the same multiple concurrent operations.
export function* watchRefreshSaga() {
  yield takeLeading(authActions.refreshStart.type, refreshSaga);
}
