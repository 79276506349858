// CONFIGURATION OPTIONS FOR THE I18N LIBRARY

// LanguageDetector to write/read localStorage or sessionStorage for language setting.
import LanguageDetector from 'i18next-browser-languagedetector';
// Core internationalization framework
import i18n from 'i18next';
// Load module initReactI18next, which integrates i19next with React
import { initReactI18next } from 'react-i18next';

// Location of translation files.
import translationEN from './translations/en.json';
import translationID from './translations/id.json';

i18n
  // Use LanguageDetector plugin
  .use(LanguageDetector)
  // Use react-i18next
  .use(initReactI18next)
  // Initialize i18next with specifc options
  .init({
    // Translations resources for i18next to use
    resources: {
      en: { translation: translationEN },
      id: { translation: translationID },
    },
    // Explicitly define supported languages
    supportedLngs: ['en', 'id'],
    // If no supported language in localStorage or browser settings, default to Bahasa Indonesia
    fallbackLng: 'id',
    detection: {
      // Detect previous language preference by checking localStorage
      order: ['localStorage', 'navigator'],
      // Storage should be localStorage (rather than cookie) because it's client-side, no HTTP request, etc.
      caches: ['localStorage'],
      // Custom localStorage name, which appears to NOT HAVE ANY EFFECT
      localStorage: 'preferredLangauge',
      // Check detected language exists in supported languages. If not, fallback to default language.
      checkWhitelist: true,
    },

    debug: false,
    interpolation: {
      // React already escapes values, so not needed here.
      escapeValue: false,
    },
  });

export { i18n };
