// * AUTHENTICATION CONTEXT (VIA REACT CONTEXT API)
// * ----------------------
// React Context API provides a way to pass data down through a component tree. This in contrast
// to passing props down manually at every level (known as "prop drilling"). React Context API
// is useful for global data that many components need access to (i.e.a authentication status).

//* LIBRARY/FRAMEWORK IMPORTS
import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// * SLICE IMPORT
import { authActions } from './authSlice';

// CREATE CONTEXT OBJECT
// Create new Context object, which will pass data through a component tree
// at every level. The object is instantiated with these default values.
const AuthContext = createContext();

// * COMPONENT
// * ---------
// The React component accepts "children" as props. In other words, this component will wrap other
// components and provide the Authentication Context object to them. The linter is being disabled
// here because the "children" prop is provided directly by React(and is not being manipulated).

// INITIALIZE AUTHENTICATION STATE & FUNCTIONS
// This provides authentication state and functions to the component tree.
/* eslint-disable-next-line react/prop-types */
export function AuthProvider({ children }) {
  // * DISPATCH AUTHENTICATION CHECK
  // This check is essential! When the app initalizes or refreshes, it verifies user's
  // authentication status using this endpoint: /api/auth/session. In doig so, it also
  // confirms that Redux Persist's "persisted" state (in localStorage) is still valid.
  // - "useDispatch" hook must be initialized before any routes or components are
  //   rendered. It will use a non-blocking call to check user's authentication
  //   state and then update the store.
  const dispatch = useDispatch();
  // - "useEffect" hook is what's responsible for dispatching the action "sessionStart"
  //   (to the watch saga), which will check authentication. Note: the actual logic for
  //    the check is in SessionSaga.js
  useEffect(() => {
    dispatch(authActions.sessionStart());
  }, [dispatch]);

  // * ACCESS STATE INFORMATION
  // The "useSelector" is used to access the Redux store's state in order
  // to retrieve values for the user's authentication state and type.
  const openedGateway = useSelector(state => state.auth.gateway); // ! REMOVE FOR OFFICIAL DEPLOYMENT
  const authenticated = useSelector(state => state.auth.session);
  const user = useSelector(state => state.auth.user);

  // * SIGN-IN/OUT CALLBACK FUNCTIONS
  // These are two callback functions designed for signing-in/out of the user.
  // Each of these functions dispatch relevant actions (with user credentials).
  const openGateway = useCallback(
    grant => {
      dispatch(authActions.setGateway(grant));
    },
    [dispatch]
  ); // ! REMOVE FOR OFFICIAL DEPLOYMENT
  const signin = useCallback(
    credentials => {
      dispatch(authActions.signInStart(credentials));
    },
    [dispatch]
  );
  const signout = useCallback(() => {
    dispatch(authActions.signOutStart());
  }, [dispatch]);

  // MEMOIZE CONTEXT VALUE
  // In order to prevent the key "value" object from being reconstructed all the time
  // (further down below), the useMemo hook is used to "memoize" the object's three values
  // into one. As a result, React will re - render ONLY when "authenticated", "user", "signin",
  // and/or "signout" inside  the "contextValue" object changes. This improves performance.
  const contextValue = useMemo(
    () => ({
      openedGateway, // ! REMOVE FOR OFFICIAL DEPLOYMENT
      authenticated,
      user,
      openGateway, // ! REMOVE FOR OFFICIAL DEPLOYMENT
      signin,
      signout,
    }),
    [openedGateway, authenticated, user, openGateway, signin, signout] // ! REMOVE PARTS FOR OFFICIAL DEPLOYMENT
  );

  // Now the values are passed down to the provider,
  // which include "authenticated", "signin", and "singout".
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

// CUSTOM HOOK (FOR EASY CONSUMPTION)
// The custom hook "useAuth" provdes easy access to this context.
// It hides all the implementation detail of this Context Object.
export const useAuth = () => useContext(AuthContext);
