// * HOOK - ERROR HANDLING
// This hook is used to capture errors off an error object. Depending on where
// this hook is used, the error can occur during data fetching or any other
// operation related to redering a component.

// * LIBRARY/FRAMEWORK IMPORTS
import PropTypes from 'prop-types';

// * HOOK
// * ----
export function ErrorHandler({ error }) {
  // Set a default error message. This will be used for times when the
  // error object does not contain detailed error informatin.
  let errorMessage = 'An unexpected error occurred';
  // HTTP ERROR
  // Dispalys HTTP errors
  if (error.status && error.statusText) {
    errorMessage = `Error ${error.status}: ${error.statusText}`;
    // SERVER ERROR
    // Displays error message from server response
  } else if (error.data && error.data.message) {
    errorMessage = error.data.message;
  }
  // Render error message and also prevent
  // component from rendering)
  return (
    <div className="text-center text-red-600 text-lg mt-5">
      {errorMessage}
    </div>
  );
}

// * PROPTYPES DECLARATION
// * ---------------------
//
ErrorHandler.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.number,
    statusText: PropTypes.string,
    data: PropTypes.shape({
      message: PropTypes.string,
    }),
    // Error prop must be provided for this
    // component to function correctly.
  }).isRequired,
};
