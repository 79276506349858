// * AUTH UTIL (TIMING LOGIC)
// * ---------
// This separate utility function contains the timing logic that's used to calculate
// when the access token will expire and then, depending on this value, it will call
// refreshSaga.js.

// * SLICE IMPORT
import { authActions } from '../authSlice';
// * UTILITY IMPORTS
import { decodeCookie, csrfCookie } from './checkCookies';

export function scheduleRefresh(dispatch) {
  try {
    // Check for existance of cookie with name "time_meta_cookie", "shield_access_cookie",
    // and "shield_refresh_cookie". Store their values in the varibles.
    const timeMetaCookieValue = decodeCookie('time_meta_cookie');
    const csrfCookieAccessValue = csrfCookie('access');
    const csrfCookieRefreshValue = csrfCookie('refresh');

    // If all 3 of these cookies exist, begin scheduling
    // refresh time for the access token.
    if (
      timeMetaCookieValue &&
      csrfCookieAccessValue &&
      csrfCookieRefreshValue
    ) {
      // Extract expiry time of access token from "time_meta_cookie"
      const { expires } = timeMetaCookieValue;
      const accessTokenExpiryDate = new Date(expires);
      console.info('Access token expiration:', accessTokenExpiryDate);

      // Get client's current time (UTC)
      const currentClientDate = new Date();
      console.info("Client's current time:", currentClientDate);

      // Calculate access token's remaining time (in milliseconds)
      const remainingTime =
        accessTokenExpiryDate.getTime() - currentClientDate.getTime();
      console.info(
        'Refresh of access token scheduled in',
        remainingTime / 60000,
        'minutes'
      );

      // SAFETY CHECK
      // This check is in place for possible misconfigurations that result in a calculation
      // that is either of wrong format or value is undefined (i.e.NaN). Since "setTimeout"
      // treats "NaN" as "0", it can trigger an immediate dispatch of the acction. This can
      // result in an infinite loop. This safety check is in place to stop such things.
      if (Number.isNaN(remainingTime)) {
        console.error(
          'Failed to calculate remaining time. This was the faulty output: ',
          expires
        );
        // Exit Early
        return;
      }

      // Set an acceptable buffer for when the access token should be
      // refreshed (right before it expires). Here it's set for 3 minutes.
      const bufferTime = 3 * 60 * 1000;

      // Schedule a delay, which will be used as a countdown by the setTimeout function.
      // Since this delay can be initalized at any time, the Math.max function is being
      // used as a precaution. It guarantees the delay value that's sent to setTimout
      // will never be zero. So between the 2 arguments, Math.max will always select
      // the largest value. As result, the shortest possible delay that might be
      // sent to setTimeout will be 5 seconds.
      const delay = Math.max(remainingTime - bufferTime, 5000);

      // Once setTimeout's internal timer passes the specified
      // number of milliseconds (set in the "delay" argument),
      // it will call refreshSaga.js.
      setTimeout(() => {
        dispatch(authActions.refreshStart());
      }, delay);
    } else {
      // If any of the 3 cookies do not exist in browser, it's likely
      // because the user is a public user and no authenticaiton is required.
      console.info('Missing cookies: No session validation required');
    }
  } catch (error) {
    // If error occurs in try block, which can happen for a variety of reasons,
    // make an attempt to catch the NON-HTTP errors.
    let curatedError = 'Error during scheduling of token refresh';

    // NON-HTTP ERRORS
    // These errors can result from JavaScript errors, etc. They do not have a response
    // object, which is an indication that it is not an HTTP error. If so, the error's
    // message property is used to isolate the error message.
    if (error.message) {
      curatedError = error.message;
    }
    // Finally, pass the properly isolated error message.
    console.error(
      'Error during scheduling of token refresh:',
      curatedError
    );
  }
}
