// * TRUE ENTRY POINT (BOOTSRTAPPED HERE)
// * ----------------
// This file is responsible for "bootstrapping" the application. It setups providers: Redux Store,
// Router, etc. It also renders the root component. This file DOES NOT define the React component
// itself. Essentially, it provides the entry point for React to take control and render the
// application in the DOM.
//
// The "Router" component has moved into this file's component tree for the following reasons:
// routing should be available throughout the entire app; it's better to separate the routing
// context from UI components.

// * LIBRARY/FRAMEWORK IMPORTS
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import Modal from 'react-modal';

// * LOCAL IMPORTS
import { AuthProvider } from './app/auth/authProvider';
import { App } from './app/App';
import { store, persistor, history } from './app/state/redux/store';
import { register } from './serviceWorkerRegistration';
import './locales/i18n';
import './styles/index.css';
import { GlobalSpinner } from './shared/ui/components/GlobalSpinner';

// import reportWebVitals from './reportWebVitals'; // !Performance monitoring disabled for now

// ACCESSIBILITY
// Helps improve accessibility. It tells screen readers
// not to read the background content when a modal is open.
Modal.setAppElement('#root');

// DEFINE ROOT ID
const root = ReactDOM.createRoot(document.getElementById('root'));

// * RENDER
// * ------
root.render(
  // STRICT MODE
  // This helps identify potential problems during development. However, it may cause
  // some components to render twice, which is by design (for detecting side effects).
  // Also, to make the Redux Store and React Query's client available to components,
  // the app is being wrapped with "Provider" & "QueryClientProvider".
  <React.StrictMode>
    {/* REDUX STORE (INCLUDES RTK QUERY, ETC) */}
    <Provider store={store}>
      {/* PERSISTOR DELAYS UI RENDERING */}
      <PersistGate loading={<GlobalSpinner />} persistor={persistor}>
        {/* REDUX FIRST HISTORY */}
        <Router history={history}>
          {/* AUTHENTICATION CONTEXT */}
          <AuthProvider>
            {/* FINALLY THE APP */}
            <App />
          </AuthProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// REGISTER SERVICE WORKER
// Necessary for the function of the Progressive Web App (PWA) chat feature located here:
// https://www.dukun.ai/hello/:slug. The CRA-packaged service worker provides offline
// capability by caching assets and API responses, reducing the need for repeated
// network requests. It also provides push notifications and background sync.
register();

// PERFORMANCE MONTIORING
// Tool for monitoring performance of the application. If it's not actively being used,
// it should be removed. If it is being used, pass an argument to to log results:
// i.e.reportWebVitals(console.log) or send it to an analytics endpoint.
// reportWebVitals(); // !Performance monitoring disabled for now
