// * PRIVATE HEADER
// * --------------
//

// * IMPORTS - GENERAL LIBRARY/FRAMEWORK
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// * IMPORTS - COMPONENTS
import { Logo } from '../../../shared/ui/layouts/header/HeaderLogo';
import { SelectorTheme } from '../../../shared/ui/layouts/header/HeaderSelectorTheme';
import { SelectorLanguage } from '../../../shared/ui/layouts/header/HeaderSelectorLanguage';
import 'flag-icons/css/flag-icons.min.css';
import { SignOutButton } from '../components/SignOutButton';

// * RENDER JSX
// * ----------

// SIDEBAR ITEMS
// Array of objects, each representing a header link with their own properties.
// In the JSX, the map function will iterate over the objects in this array and
// render the NavLink for each object.
const headerObjects = [
  { to: '/provider/dashboard', label: 'Header.1A' },
  { to: '/provider/patients', label: 'Header.2A' },
  { to: '/provider/account/qrcode', label: 'Header.3A' },
];

// * FUNCTION COMPONENT - HEADER
// * ---------------------------

export function PrivateHeader() {
  // I18NEXT TRANSLATION
  // Call useTranslation using 't' function.
  const { t } = useTranslation();

  // HEADER FUNCTION
  const [isScrolled, setIsScrolled] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  let timeoutId;

  const handleScroll = () => {
    // Determine if the page is scrolled more than a small threshold (e.g., 50 pixels)
    const isPageScrolled = window.scrollY > 50;

    // Clear any existing timeout to avoid hiding the header while actively scrolling
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setIsScrolled(isPageScrolled);
    setHideHeader(false); // Always show the header initially when scrolling starts

    // Only set a timeout to hide the header if the page is scrolled
    if (isPageScrolled) {
      timeoutId = setTimeout(() => {
        setHideHeader(true);
      }, 1200); // Adjust the timeout as needed
    } else {
      // If the page is scrolled to the top, ensure the header is shown
      setHideHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  // RETURN JSX
  return (
    <header
      className={`h-28 transition-transform duration-500 ${hideHeader ? '-translate-y-full' : ''}`}
    >
      <nav
        className={`fixed flex w-5/6 mt-4 py-2 px-4 bg-white items-center mx-auto
                    rounded-lg border border-gray-100 transition-shadow duration-500
                    justify-between left-0 right-0 z-10 
                    dark:bg-mac-450 dark:border-mac-575
                     ${
                       isScrolled
                         ? 'shadow-lg shadow-blue-200'
                         : 'shadow-none'
                     }`}
      >
        <ul className="flex gap-4">
          <li className="flex mr-5">
            <Logo />
          </li>
          {headerObjects.map(({ to, label }) => (
            <li className="flex items-center" key={to}>
              <NavLink
                to={to}
                className={({ isActive }) =>
                  isActive
                    ? 'px-3 pb-4 -mb-5 border-b-2 border-blue-400 hover:border-blue-400'
                    : 'px-3 pb-4 -mb-5 border-b-2 border-transparent hover:border-blue-200'
                }
              >
                {t(label)}
              </NavLink>
            </li>
          ))}
        </ul>
        <ul className="flex gap-4">
          <li className="flex items-center">
            <SelectorTheme />
          </li>
          <li className="flex items-center">
            <SelectorLanguage />
          </li>

          <li className="flex items-center">
            <SignOutButton />
          </li>
        </ul>
      </nav>
    </header>
  );
}
