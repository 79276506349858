// * AXIOS API SERVICE
// * -----------------
// This is the GLOBAL configuration file for axios, a promise-based HTTP client used to make API
// requests. Axios is unaware ("agnostic") of the Redux store. It should only provide methods to
// perform HTTP requests and return responses or errors. It shoud NOT dispatch various actions
// in response to API calls or contain any Redux logic. Instead, the dispatch of actions must
// be handled in sagas or componenets where the API is being called.
//
// It should be noted that Axios is integrated with Redux Tookit's (RT) Query. Axios takes care
// of standardizing API calls: setting up base URLs, request/response headers, and interceptors
// (for advanced token management). On top of Axios, RTK Query is used to manage data fetching
// state: caching, updating, and providing data to React components.
//
// NOTE: Ensure paths are correctly set for JWT/CSRF cookies on backend. Check these variables:
// JWT_REFRESH_COOKIE_PATH, JWT_ACCESS_COOKIE_PATH, JWT_ACCESS_CSRF_COOKIE_PATH, etc.

// * LIBRARY/FRAMEWORK IMPORTS
import axios from 'axios';

// * LOCAL IMPORTS
import { csrfCookie } from '../auth/utils/checkCookies';

// * CREATE AXIOS INSTANCE
// * ---------------------
// This creates the axios instance that uses a predefined base URL, ensures that HttpOnly cookies
// (containing access & refresh tokens) go out with EVERY request, and also sets the content type
// for each one of these requests.The only configuration that is not "automatic" here is the CSRF
// configuration (which is done further down below).

const api = axios.create({
  // Access environment variable from ".env" for endpoint url.
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// * REQUEST INTERCEPTOR
// * -------------------
// Before any request is sent out, the request interceptor modifies
// the request configuration to include the CSRF token in the headers.

// * CSRF TOKEN INJECTION

api.interceptors.request.use(
  config => {
    // Instead of directly mutating, which could cause side effects, the config object
    // is cloned. This object represents the request configuration used for HTTP requests.
    const clonedConfig = { ...config };
    // If the request URL is /api/auth/refresh OR /api/auth/refresh, get the value of the
    // CSRF refresh token; otherwise get the value of the CSRF access token'.
    const csrfToken =
      config.url.endsWith('/auth/refresh') ||
      config.url.endsWith('/auth/revoke')
        ? csrfCookie('refresh')
        : csrfCookie('access');

    // Logging the initial request URL
    console.info(`Initiating request to: ${clonedConfig.url}`);

    // If CSRF token exists (in the client browser), it means the user has
    // previously been authenticated. So add their CSRF token to the header.
    if (csrfToken) {
      clonedConfig.headers['X-CSRF-TOKEN'] = csrfToken;
      console.info(
        'CSRF token found: Attached to request header:',
        csrfToken
      );

      // If CSRF token does NOT exist (in the client browser), it means the user has
      // NOT been authenticated. So there's nothing to attach to the header.
    } else {
      console.info(
        'CSRF token NOT found: Nothing to attach to request header'
      );
    }

    return clonedConfig;
  },

  // * PRE-REQUEST ERRORS

  error => {
    // If an error occurs during request preperation (i.e. constructing config object),
    // propogate the eror down the promise chain. This will allow the calling code to
    // handle the error using .catch() or async/await (with try/catch).
    return Promise.reject(error);
  }
);

// * RESPONSE INTERCEPTOR
// * --------------------
// When a response arrives, the response interceptor handles the response before passing it to the
// .then() method of the request, which eventually makes its way back to the calling code. It also
// listens on every response for global errors and for  expired tokens(i.e. "unauthorized error"
// status code 401) and then takes appropriate action.

// * ALL STATUS CODES

api.interceptors.response.use(
  // Any status code that is in the 200s is handed-off to this calling code. Note: this condition
  // is necessary; without this condition, responses (with status code 200) will not get through.
  response => {
    // This simply returns the respons object without any modifications.

    // Log successful response details
    console.log(
      `Response received from: ${response.config.url}`,
      response
    );

    return response;
  },

  // * RESPONSE ERRORS

  // If response returns server error, the functions below are called.
  async error => {
    // NETWORK ISSUES
    // --------------
    // Before even attempting any check for response status codes (further down below),
    // perform an initial network error check: i.e. No HTTP response received because
    // server unreachable, DNS lookup failure, etc. If so, forward error back to code
    // that originally made the request (which resulted in this network error).
    if (!error.response) {
      console.error(
        `Network error. Response from: ${error.config.url}`,
        error.response
      );
      return Promise.reject(error);
    }
    // Specifically log the status code
    console.error(`Response code: ${error.response.status}`);

    // HTTP ERRORS (401 UNAUTHORIZED)
    // -----------
    // This condition catches respones from ANY endpoint that returns a status code 401.
    // Since status code 401 is usually associated with an expired/invalid access token,
    // this condition attempts to renew (just once) the seemingly expired/invalid access
    // token by sending it to the refresh endpoint.

    // If response status 401 (Unauthorized) and NOT already tried (i.e. no "retry" flag)
    // and NOT going to "/api/auth/refresh", then flag the response
    // before it goes out in a new request.
    if (
      error.response.status === 401 &&
      !error.config.retry &&
      !error.config.url.endsWith('/auth/refresh')
    ) {
      // In the event a request to the revoke endpoint fails with a 401 unauthorized,
      // do not attempt to refresh the access token (as this is counterproductive).
      // Instead, directly reject the promise.
      if (error.config.url.endsWith('/auth/revoke')) {
        // Logic for revoke endpoint when 401 Unauthorized is received
        console.error(
          'Request to /auth/revoke - Returned 401 unauthorized: Will not retry'
        );
        return Promise.reject(error);
      }

      // The config object represents the configuration used for HTTP requests.
      // It is directly mutated--setting "retry" flag on the original request--
      // to prevent infinite loop of refresh attempts.
      // eslint-disable-next-line no-param-reassign
      error.config.retry = true;
      console.info('Received response code 401: Attempting token refresh');

      try {
        // Asyncronously call token refresh endpoint and await its response,
        // which should contain the new access token.
        await api.post('/auth/refresh');
        // If response status code 200 received, it means the a new access token
        // was successfully obtained. So, retry original request.
        return api(error.config);

        // ERRORS DURING TOKEN REFRESH
        // If during this "try" operation failure occurs, examine the error
        // and take appropriate course of action.
      } catch (refreshError) {
        // After token refresh failure (specifically for error codes 400,401, or 403),
        // a hard redirect (full-page reload) occurs to sign-in page.
        if (
          refreshError.response &&
          [400, 401, 403].includes(refreshError.response.status)
        ) {
          // Redirect to login or perform other sign-out
          // ! It might make sense to also clear the tokens from localStorage since
          // ! the user will have to re-authenticate again manually.
          console.info(
            'Token refresh failed (error 400, 401, or 403): Redirecting to sign-out'
          );
          window.location.href = '/sign-in';
        } else {
          // For all other others (i.e. network isses), instead of redirecting
          // to sign-in page (and disrupting user experience), just log to console.
          console.error(
            'Non-auth error during token refresh:',
            refreshError
          );
        }
        return Promise.reject(refreshError);
      }
    } else {
      // ALL OTHER ERRORS
      // ----------------
      // If response is NOT status code 401 (or there was a previous "retry" attempt),
      // the interceptor will break out from this block and forward the error back to
      // the code that made the original request (which resulted in this response).
      // In doing so, the error propogates through the promise chain.
      return Promise.reject(error);
    }
  }
);

export { api };
