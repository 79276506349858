// * SAGA - SIGNINSAGA
// * -----------------
// This file contains generator functions (sagas), which handle complex asynchronous workflows.
// This includes making API calls using the axios service defined in apiService.js and also
// side effects (i.e. dispatching redux actions) based on the outcomes of those API calls.
//
// Sagas should not import the store directly.Instead, it should take what it needs from the
// action payload and use effects (i.e.call) to make API calls, "put" to dispatch actions, and
// "select" to access the Redux store.

// * LIBRARY/FRAMEWORK IMPORTS
import { call, put, takeLatest } from 'redux-saga/effects';

// * FILE IMPORTS
import { api } from '../../api/axiosClient';
import { authActions } from '../authSlice';
// import { apiSlice } from '../../api/apiSlice';

// SIGN-IN SAGA
// ------------
function* signInSaga(action) {
  try {
    // Extract "loginMethod" to determine conditional logic here. However,
    // remove it completely before sending "credentials" to the backend.
    // Otherwise validation will fail.
    const { loginMethod, ...credentials } = action.payload;

    const response = yield call(api.post, '/auth/access', credentials);
    // SUCCESSFUL SUBMISSION
    if (response.status === 200) {
      // MAGIC LINK SIGN-IN
      if (loginMethod === 'magicLink') {
        // After successful submission of email to backend, react-toastify
        // is used to display a notification from the component or saga
        // based on the action's outcome. Thus nothing is needed here
        // at the moment.
        // PASSWORD SIGN-IN
      } else {
        yield put(authActions.signInSuccess(response.data));
        // RTK Query will invalidate any cached data associated with the key "providerData".
        // As a result, a refetch of "providerData" will be performed. This ensures data is
        // fresh after sign-in.
        /// //apiSlice.invalidateQueries('providerData');
      }
      // FAILED SUBMISSION
    } else {
      // Handle non-200 responses or other login methods if necessary
    }
    // FAILED API CALL
  } catch (error) {
    console.log('Sgn-in failure from singInSaga.js');
    yield put(
      authActions.signInFailure({
        message: error.message,
        status: error.response?.status,
      })
    );
  }
}

// The takelatest effect is listining for this specific action type
// that's in the authSlice.
export function* watchSignInSaga() {
  yield takeLatest(authActions.signInStart.type, signInSaga);
}
