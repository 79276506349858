// Add scroll event listener with the following hooks:
// useState: Add local state (for global click event listener)
// useRef: Add reference to the dropdown's DOM node (for clicks to work outside the dropdown)
// useEffect: Add side effects handling (to manage global event listener separate from render)
import React, { useState, useRef, useEffect } from 'react';
// Load useTranslation hook + flag icons (CSS library)
import { useTranslation } from 'react-i18next';
import 'flag-icons/css/flag-icons.min.css';

// DEFINE LANGUAGESELECTOR
function SelectorLanguage() {
  // Destructure the i18n object in order to access various methods
  const { i18n } = useTranslation();
  // Create state variable isOpen and set initial value to false
  const [isOpen, setIsOpen] = useState(false);
  // Create reference to the dropdown's DOM node (in order to detect clicks outside the dropdown)
  const dropdownRef = useRef(null);

  // Function to handle clicks outside the dropdown
  useEffect(() => {
    // Check if cliick is outside dropdown. If so, setIsOpen value to false.
    const handleClickOutside = event => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    // Function adds event listener...
    document.addEventListener('mousedown', handleClickOutside);
    // Function removes event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Array of two langauge objects. The country_code is used to get flag icon classes.
  const languages = [
    { code: 'id', name: 'Bahasa Indonesia', country_code: 'id' },
    { code: 'en', name: 'English', country_code: 'gb' },
    // Add more languages here if needed
  ];

  // Function gets called when languge option is selected from dropdown.
  // It changes the website's current language and then closes the dropdown.
  const handleLanguageChange = language => {
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  // Find the browser's current language setting and strip unnecessary suffix (i.e. en-US).
  const browserLanguage = i18n.language.split('-')[0];
  // Find the current language code by checking for user selected language (from dropdown).
  // If none selected, the "fallbackLng" value in i18next configuration is used.
  const currentLanguage =
    languages.find(lang => lang.code === browserLanguage) ||
    languages.find(lang => lang.code === i18n.options.fallbackLng);

  return (
    <div className="relative text-left" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        className="
              inline-flex px-4 py-3
              hover:rounded-md hover:bg-gray-50 hover:shadow-inner hover:text-gray-500
              focus:rounded-md focus:bg-gray-50 focus:shadow-inner focus:outline-none"
        // Accessibility attribute indicates element triggers a menu
        aria-haspopup="true"
        // Accessibility attribute indicates whether menu is open or closed
        aria-expanded={isOpen}
        // Accessibility attribute
        aria-label="Change language"
      >
        <span
          // Display country flag (based on country code) from library
          className={`fi fis fi-${currentLanguage?.country_code}
           rounded-full border-0 shadow bg-white h-4`}
        />
        <svg
          className="-mt-0.5 -me-1 ms-2.5 h-4 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10
            12.586l3.293-3.293a1
            1 0 011.414 1.414l-4 4z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className="
          absolute right-0 mt-2
          rounded-md ring-black ring-opacity-5 shadow-lg bg-white "
          // Accessibility attribute: dropdown is a menu
          role="menu"
          // Accessibility attribute: menu orientation is vertical
          aria-orientation="vertical"
          // Accessibility attribute: link menu to a button for screen readers
          aria-labelledby="language-selector"
        >
          <div className="py-3" role="none">
            {languages.map(language => (
              <button
                // Option generated for each language
                key={language.code}
                onClick={() => handleLanguageChange(language.code)}
                className="
                w-full px-5 py-3 text-sm items-center inline-flex whitespace-nowrap
                hover:bg-gray-100"
                role="menuitem"
                type="button"
              >
                <span
                  // Flag for each generated langauge
                  className={`fi fis fi-${language.country_code} 
                  rounded-full border-0 shadow bg-white h-full`}
                  style={{ marginRight: '0.5rem' }}
                />
                {language.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export { SelectorLanguage };
