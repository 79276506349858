// * THEME CONTEXT (VIA REACT CONTEXT API)
// * -------------
// React Context API provides a way to pass data down through a component tree. This in contrast
// to passing props down manually at every level (known as "prop drilling"). React Context API
// is useful for global data that many components need access to (i.e. theme).

// * LIBRARY/FRAMEWORK IMPORTS
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';

// CREATE CONTEXT OBJECT
// Create new Context object, which will pass data through a component tree
// at every level. The object instantiated with these default values.
const ThemeContext = createContext({
  Theme: 'dark',
  // Placeholder function for toggling the state.
  // It will be replaced further down below.
  toggleTheme: () => {},
});

// * COMPONENT
// * ---------
// The React component accepts "children" as props. In other words, this component will wrap
// other components and provide the Theme Context object to them. The linter is being disabled
// here because the "children" prop is provided directly by React (and is not being manipulated).
// ! An inline script, located in index.html, is associated with this script.
// ! Please see that file for additional explanation.

// INITIALIZE THEME STATE & FUNCTIONS
// This provides theme state and functions to the component tree.
/* eslint-disable-next-line react/prop-types */
export function ThemeProvider({ children }) {
  // The "useState" hook is used to declare the state variable "Theme" and
  // the function "setTheme" will be used to update that state variable.
  const [Theme, setTheme] = useState(() => {
    // Read value of "Theme" from localStorage and apply it as a class to the <html>.
    // If no value is present in localStorage, default to "light". Note: The code here
    // is nearly identical to the code in index.html. It's still necessary here because
    // it synchronizes React state with the DOM, which is necesary for consistent behavior
    // throughout the app cycle.
    return localStorage.getItem('Theme') || 'light';
  });

  // TOGGLE LOGIC
  // Whenever the "Theme" state changes, "useEffect" is run. The "useEffect" hook toggles
  // the Theme value in the HTML class and then updates localStorage with this value.
  useEffect(() => {
    // Apply value of "Theme" to the <html> element
    document.documentElement.className = Theme;
    // Update localStorage
    localStorage.setItem('Theme', Theme);
  }, [Theme]);

  // DEFINE TOOGLE FUNCTION
  // Creates function "toggleTheme", which toogles "Theme" state. It uses the "setTheme"
  // function, which was defined in useState (above), to update thee "Theme" state.
  const toggleTheme = () => {
    // Toggle between 'light' and 'dark'
    setTheme(Theme === 'light' ? 'dark' : 'light');
  };

  // MEMOIZE CONTEXT VALUE
  // In order to prevent the key "value" object from being reconstructed all the
  // time (further down below), the useMemo hook is used to "memoize" the object's
  // two values into one. As a result, React will re-render ONLY when "Theme" inside
  // the "contextValue" object changes. This improves performance.
  const contextValue = useMemo(() => ({ Theme, toggleTheme }), [Theme]);

  // Now the values are passed down to the provider,
  // which  include "Theme" and "toggleTheme".
  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

// CUSTOM HOOK (FOR EASY CONSUMPTION)
// The custom hook "useTheme" provdes easy access to this context
// (i.e. Theme & toggleTheme). It hides all the implementation
// detail of this Context Object.
export const useTheme = () => useContext(ThemeContext);
