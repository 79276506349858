import React, { useEffect, useState } from 'react';

export function GlobalSpinner() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return isVisible ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-16 h-16 bg-blue-300 rounded-full animate-pulse" />
    </div>
  ) : null;
}
