import React from 'react';
import { useSelector } from 'react-redux';

import { PublicHeader } from '../../../../public/ui/layouts/PublicHeader';
import { PrivateHeader } from '../../../../provider/ui/layouts/PrivateHeader';

function Header() {
  const session = useSelector(state => state.auth.session);

  return session ? <PrivateHeader /> : <PublicHeader />;
}

export { Header };
