// * AUTH MIDDLEWARE
// * ---------------
// This middleware is intermediary layer between dispatched actions and the reducer. This layer
// handles side effects, asynchronous actions, enhancement of actions (with additional data),
// and logic (not directly related to the state update). In short, it can be thought of as a
// "control flow mechanism" for logic that should not be in the UI or reducers.

// * LOCAL IMPORTS
import { scheduleRefresh } from './utils/scheduleRefresh';

// * MIDDLEWARE LOGIC
// * ----------------
// This middleware is being used here to pass the "dispatch" function to scheduleRefresh.js,
// which will be used to eventually make a call to refreshSaga.js. This middleware listens
// for the action "auth/refreshStart" to be dispatched (from sessionSaga.js).

// The "authMiddleware" function contains a series of functions, which use closures to access
// the following: redux "store" object (which provided access to dispatch method); the "next"
// function (which passes an actions to the next middleware/reducer pipeline); the "action"
// paylaod, which has a "type" property, currently being processed by the middleware.
export const authMiddleware = store => next => action => {
  // If action of type "auth/refreshStart" is caught, call scheduleRefresh.js.
  // When calling this utility function, also pass the "dispatch" function by
  // using "store.dispatch".
  if (action.type === 'auth/refreshTimer') {
    // Call scheduleRefresh along with "dispatch"
    // (being passed from the "store" object).
    scheduleRefresh(store.dispatch);
  }
  return next(action);
};
