// * ROOT SAGA
// * ----------
// This file aggregates all Redux Sagas in a single place. It's best to do it here rather than
// store.js primarily because of separtion of concerns: we want to isolate side effect handling
// from state management configuration (store.js). Also, as the app grows and so does the number
// of sagas, this setup offers more scalability and flexibility.
//
// Also, the Redux store's dispatching logic should be kept here (within the sagas) and not in
// the service layer (i.e. ApiService.js). All sagas should be combined here and then initiated
// concurrently in a non-blocking manner.

// * LIBRARY/FRAMEWORK IMPORTS
import { all, fork, put } from 'redux-saga/effects';

// * ALL SAGAS
import { watchSignInSaga } from '../../auth/sagas/signInSaga';
import { watchSignOutSaga } from '../../auth/sagas/signOutSaga';
import { watchSessionSaga } from '../../auth/sagas/sessionSaga';
import { watchRefreshSaga } from '../../auth/sagas/refreshSaga';
// * LOCAL IMPORTS - OTHER
import { api } from '../../api/axiosClient';
import { authActions } from '../../auth/authSlice';

// * ROOTSAGA LOGIC
// * --------------

// * GLOBAL ERROR HANDLERS
// Since sagas are responsible for handling side effects (i.e. API calls, conditional logic based
// on those calls, response to asynchronous events, etc), this is a good location to centralize
// side effect management(rather than spreading it across the app).

// 401 UNAUTHORIZED CALLBACK FUNCTION
// During API calls, this function acts as a global listener for authentication-related errors.
// When an unauthorized access error occurs (namely status code 401), usually because the user
// session has expired or the session has become invalid (token revoked), this function will
// dispatch a sign-out action. Note: the dispatch action is wrapped into a handler function
// so that this action is accessible to third-party utilities (i.e. Axios) that are outside
// the Redux ecosystem.
function* onUnauthorized() {
  yield put(authActions.signOutStart); // ! YOU MIGHT WANT TO MODIFY THIS LATER TO PASS ERRORS TO THIS.
}

// * EXPORT ALL SAGAS
// There is a cyclic dependency (having to do with the Redux Persist configuration) between
// signOutSaga.js, rootSaga.js(this file), and store.js. To resolve this, the "persistor" is
// being passed down here to any child saga that requires it.
export function* rootSaga(persistor) {
  // UNAUTHORIZED CALLBACK
  // This sets the unauthorized callback function (in apiClient.js).
  api.onUnauthorized = onUnauthorized;

  yield all([
    fork(watchSessionSaga),
    fork(watchRefreshSaga),
    fork(watchSignInSaga),
    fork(watchSignOutSaga, persistor),
  ]);
}
