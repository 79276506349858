// * REDUX STORE
// * -----------
// This is the object that holds the app's state tree. In other words, this is where the entire
// state of the app lives. Redux is typicaly used to manage global state, client state, app state,
// and routing. Redux Saga is primarily used for scenarios where the app requires the handling of
// side effects: data fetching (i.e.API calls), websockets, more complex asynchronous logic, etc.

// * LIBRARY/FRAMEWORK IMPORTS
// Redux Toolkit's "configureStore" simplifies Redux store setup. It's "combineReducers"
// is a helper function that combines multiple slice refucers into a single root reducer.
import { combineReducers, configureStore } from '@reduxjs/toolkit';
// Redux Saga's "createSagaMiddleware" is used for handling side effect
// (i.e.asynchronous actions).
import createSagaMiddleware from 'redux-saga';
// Redux First History's "createReduxHistoryContext" is a function that creates a history context
// for Redux. This context includes middleware and reducer to syn navigation events with the store.
import { createReduxHistoryContext } from 'redux-first-history';
// History's "createBrowserHistory" is a function that creates a history object that keeps track
// of the URL changes in the app.
import { createBrowserHistory } from 'history';
// React Persist
import { persistReducer, persistStore } from 'redux-persist';
// React Persist's storage type setting: either localStorage or sessionStorage
import storage from 'redux-persist/lib/storage';
// Redux Logger
import { createLogger } from 'redux-logger';

// * ALL SLICES
import { apiSlice } from '../../api/apiSlice';
import { authReducer } from '../../auth/authSlice';
import { authMiddleware } from '../../auth/authMiddleware';
// * ROOT SAGA
// This is the root saga that combines all individual sagas in a single entry point
import { rootSaga } from './rootSaga';

// * REDUX PERSIST OBJECT
// * --------------------
// This is Redux Persist's configuration in the form of an object. It specifies the settings
// for the cleint-side storage: the key under which the state object is stored; the storage
// type to use; specific redux state slice that should persist. Note: consider implmenting
// sesstionStorage (instead of localStorage) because the former is cleared when the browser
// or tab are closed.
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth'],
};

// * BROWSER HISTORY OBJECT
// * ----------------------
// The browser history object has two purposes: manage navigation by redirecting user to new route
// without full page refresh and mantain history of all locations (URLs) visited by user. With the
// introduction of Redux First History, the object can now be synced with the Redux store. To do
// all this, it must first be properly configured here.

// INITIALIZE BROWSER HISTORY
// Initialization of the standard browser history object.
const history = createBrowserHistory();

// REDUX HISTORY CONTEXT
// A Redux History Context allows for the binding of the navigation history to the Redux store.
// This is achieved with the use of several objects and functions from Redux First History.
// Specifically, the function CreateReduxHistoryContext creates this Redux context around
// the history object using the following:
// routerMiddleware - middleware that captures navigation actions and passes them to routerReducer.
// routerReducer    - reducer updates the Redux state (in response to previous navigation actions).
// createReduxHistory - function creates history object synced with Redux store.
const { routerMiddleware, routerReducer, createReduxHistory } =
  createReduxHistoryContext({
    history,
  });

// * SETUP REDUX STORE
// * -----------------

// COMBINE REDUCERS
// Combine reducers from slices: authSlice.js (authReducer), and apiSlice.js (apiSlice).
// Also include Redux First History's routerReducer.
const rootReducer = combineReducers({
  auth: authReducer,
  router: routerReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

// INITIALIZE REACT PERSIST
// The Redux Persist config object, which was created earlier, is passed into an argument along
// with rootReducer and wrapped in the persistReducer function. This enables Redux Presist to
// automatically hydrate the app's state on launch and persist state updates.
const persistedReducer = persistReducer(persistConfig, rootReducer);

// INITALIZE SAGA MIDDLEWARE
// Instance of Redux Saga middleware for handling side effects.
const sagaMiddleware = createSagaMiddleware();

// CONFIFURE MIDDLEWARE
// Redux Toolkit is used here to get the default middleware. An array of middleware is then created
// (starting with the default). Finally, the "customizedMiddleware" function will be added to the
// Redux store further down below. Note: The middleware here is changed to customizedMiddleware
// in order to resolve a "non-serialiazable value" warning (see down below).
const middlewareEnhancer = getDefaultMiddleware => {
  let customizedMiddleware = getDefaultMiddleware({
    // Redux Toolkit's default middleware checks that all dispatching actions or storing states
    // are serializable. However, some actions generated by Redux Persist contain functions that
    // are inherently non-serializble. This is expected but the middleware still throws an error.
    // So customizedMiddleware is used here to ignore specific actions from Redux Persist.
    serializableCheck: {
      ignoredActions: ['persist/PERSIST', 'persist/PURGE'],
    },
    // The array below contains the following additional middlewares:
    // (a) Redux First History's routerMiddleware, (b) SagaMiddleware, and
    // (c) api middleware (from apiSlice).
  }).concat(
    routerMiddleware,
    sagaMiddleware,
    authMiddleware,
    apiSlice.middleware
  );

  // This conditioanlly enables additional middleware:
  // loggerMiddlware and actionDebuggerMiddleware
  if (process.env.NODE_ENV === 'development') {
    // INITIALIZE REDUX LOGGER MIDDLEWARE
    // Instance of Redux Logger Middleware for logging actions dispatched and state changes.
    // The log is visible in the console. It's useful during development and, therefore,
    // conditionally enabled.
    const loggerMiddleware = createLogger();

    // INITIALIZE DEBUGGING MIDDLEWARE
    // Instance of Debugging Middleware will log the action being dispatched and then pass the action
    // along to the next middleware in the chain.It is useful for seeing exactly what actions are
    // being dispatched in the app BEFORE they hit reducers or other middleware (i.e. Redux Saga).
    // Note: The structure of middleware requires acceptiing "store" in the very first parameter
    // (even though it's not used), so its non-necessity is being indicated with the "_".
    const actionDebuggerMiddleware = _ => next => action => {
      console.log('Dispatching:', action);
      return next(action);
    };
    // Concatentate, previously defined middleware,
    // into array and reassign it to "customizedMiddleware".
    customizedMiddleware = customizedMiddleware.concat(
      loggerMiddleware,
      actionDebuggerMiddleware
    );
  }
  return customizedMiddleware;
};

// CREATE REDUX STORE
// The Redux Store is, by default, already configured with Redux Thunk (courtesy of Redux Toolkit).
// The combined Reducer, which holds the rootReducer and Redux First History's routerMiddleware
// (to handle navigation actions), are added here. There's also the middleware constant here
// that brings in additional middleware (described above).
const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewareEnhancer,
});

// * CREATE AND EXPORT
// * -----------------

// CREATE PERSIST STORE
// This persistStore function takes the Redux store and returns a persistor object,
// which is used for maanging the rehydration process.
const persistor = persistStore(store);

// RUN SAGA MIDDLEWARE
// The saga middleware must be run to initiate all sagas. If the middleware is not run here
// then none of the sagas will execute, resulting in no saga side effects (i.e. data fetching).
// Note: "persistor" object is being passed here as argument to avoid cyclic dependency.
sagaMiddleware.run(rootSaga, persistor);

// CREATE REDUX HISTORY OBJECT
// This create a special version of the history object, effectively a "reduxHistory" object that's
// wrapped around the usual "history" object. This new object ensures that navigation actions are
// dispatched to the Redux store. This way route changes stay in sync with the Redux state and
// vice versa. Skipping this step will result in no navigation history being reflected in the
// Redux state.
const reduxHistory = createReduxHistory(store);

// CONSOLE LOGGING
// This outputs initial state (i.e. user slice initialization) of the store for debugging.
console.log(store.getState());

// The Redux store, React persistor, and reduxHistory all exported
// for use throughout the app as history.
export { store, persistor, reduxHistory as history };
