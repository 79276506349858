import React from 'react';
import { useDispatch } from 'react-redux';

import { authActions } from '../../../app/auth/authSlice';

export function SignOutButton() {
  const dispatch = useDispatch();

  // SIGN OUT HANDLER
  const handleSignOut = () => {
    dispatch(authActions.signOutStart());
  };

  return (
    <button
      aria-label="Sign-out"
      type="button"
      onClick={handleSignOut}
      className="border border-gray-200 rounded-md px-3 py-2
                 hover:rounded-md hover:border-gray-100 hover:bg-gray-50 hover:shadow-inner hover:text-gray-500
                 focus:rounded-md focus:bg-gray-50 focus:shadow-inner focus:outline-none"
    >
      Sign out
    </button>
  );
}
