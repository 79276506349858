// * CSRF COOKIES
// * ------------

// * CSRF TOKEN VALUE EXTRACTION
// This helper function not only checks for the existance of the CSRF token but also extracts
// and retrurns the value of the given cookie's name. If no value is found, it returns "null".
// This function is currently used axiosiClient.js, by the request interceptor, in order to
// include the token's value in the request header (as part of the authentication scheme).
export const csrfCookie = tokenType => {
  let cookieName;
  switch (tokenType) {
    case 'access':
      cookieName = 'shield_access_cookie=';
      break;
    case 'refresh':
      cookieName = 'shield_refresh_cookie=';
      break;
    default:
      console.warn(
        'Function called with invalid argument: Expected "access" or "refresh"'
      );
      return null;
  }

  // Attempt to find and decode the CSRF token from the cookie
  const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith(cookieName));

  return csrfToken ? decodeURIComponent(csrfToken.split('=')[1]) : null;
};

// * META COOKIE
// * -----------

// * DECODE META COOKIE
// This utility is designed to decode cookies whose values are base64-encoded. It decodes
// the value into a JavaScript Object.Its value(s) can then be used to properly setup
// renewal of the access token (in refreshSaga.js).
export function decodeCookie(cookieName) {
  // Inspect header for the cookie of interest
  // ("${cookieName}") and extracts its value.
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];

  // If cooke of interest does not exist in header,
  // print error to console.
  if (!cookieValue) {
    console.info(`Could not find cookie with name: "${cookieName}"`);
    return null;
  }

  try {
    // Decode cookie from base64-encoded string to JSON string,
    // then parse JSON string to JavaScript Object and return value
    return JSON.parse(atob(decodeURIComponent(cookieValue)));
  } catch (error) {
    // If error occures during decoding, print to console.
    console.error(
      `Error encountered while decoding cookie: "${cookieName}"`,
      error
    );
    return null;
  }
}

// * DELETE META COOKIE
// This utility simply deletes the cookie. The function sets the max-age to a negative value,
// which instructs the browser to immediately expire (delete) the cookie. In order for this
// function to work properly, the correct name, path, and domain (optional) must be
// supplied as arguments.
export const deleteCookie = (name, path, domain) => {
  let cookieString = `${name}=; SameSite=Strict; Secure; Max-Age=-99999999; path=${path};`;

  // If domain provided, add it to cookie string
  if (domain) {
    cookieString += ` domain=${domain};`;
  }

  // Set cookie, so browser effectively deletes it
  document.cookie = cookieString;
};
