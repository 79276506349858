import React from 'react';
// Import icons from React Icons or any other icon library you prefer
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa';

export function Footer() {
  return (
    <footer className="bg-gray-800 text-white mt-24">
      <div className="max-w-6xl mx-auto px-4 py-10">
        <div className="flex flex-wrap justify-between">
          {/* About Section */}
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h5 className="uppercase font-bold mb-2.5">About Us</h5>
            <p className="text-gray-400">
              Short description about your company. Something about the
              mission or vision.
            </p>
          </div>

          {/* Quick Links */}
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h5 className="uppercase font-bold mb-2.5">Quick Links</h5>
            <ul className="text-gray-400">
              <li className="mb-2">
                <a href="#!">Link One</a>
              </li>
              <li className="mb-2">
                <a href="#!">Link Two</a>
              </li>
              <li className="mb-2">
                <a href="#!">Link Three</a>
              </li>
              <li>
                <a href="#!">Link Four</a>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h5 className="uppercase font-bold mb-2.5">Contact</h5>
            <ul className="text-gray-400">
              <li className="mb-2">info@example.com</li>
              <li className="mb-2">+1 234 567 8900</li>
              <li>1234 Street Name, City</li>
            </ul>
          </div>

          {/* Social Media Icons */}
          <div className="w-full md:w-1/4 mb-6 md:mb-0 flex space-x-4">
            <a
              href="#!"
              className="text-gray-400 hover:text-white"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="#!"
              className="text-gray-400 hover:text-white"
              aria-label="Twitter"
            >
              <FaTwitter />
            </a>
            <a
              href="#!"
              className="text-gray-400 hover:text-white"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="#!"
              className="text-gray-400 hover:text-white"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        <div className="text-center text-gray-400 pt-10 sm:pt-12 font-light flex items-center justify-center">
          © {new Date().getFullYear()} dukun.ai - All rights reserved.
        </div>
      </div>
    </footer>
  );
}
