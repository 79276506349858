// * SIGN-OUT SAGA
// * -------------
// This file contains generator functions (sagas), which handle complex asynchronous workflows.
// This includes making API calls using the axios service defined in apiClient.js and also
// side effects (i.e. dispatching Redux actions) based on the outcomes of those API calls.
// Please note that sagas should not import directly from the Redux store.
// Instead, they should:
// - Take from the action payload
// - Make use of "put" to dispatch actions
// - Make use of effects (i.e. call) for API calls
// - Make use of "select" to access the Redux store

// * LIBRARY/FRAMEWORK IMPORTS
import { call, put, takeLeading } from 'redux-saga/effects';
import { push } from 'redux-first-history';

// * LOCAL IMPORTS
import { api } from '../../api/axiosClient';
// import { apiSlice } from '../../api/apiSlice';
import { deleteCookie } from '../utils/checkCookies';
// * SLICE IMPORTS
import { authActions } from '../authSlice';

// * SIGN-OUT SAGA LOGIC
// * -------------------
// This global hook is responsible for signing the user out. The API call for this is handled
// in the global apiClient.js, which also has the logic to attach the CSRF refresh token to
// the request made to token revoke endpoint: /api/auth/revoke. The file then focuses on
// on reseting

// Redux Persist configuration is being passed into the
// argument here because it's used to purge persistance state.
function* signOutSaga(persistor) {
  try {
    // * AXIOS - API CALL TO REVOKE ENDPOINT
    // Explcitily make a call to the endpoint responsible for revoking tokens. Axios has been
    // customized to detect the call to this endpoint and it will attach a CSRF refresh token
    // to the header of the request. The server, upon successful contact, will place the JWT
    // refresh token into its RevokedToken table.
    yield call(api.post, '/auth/revoke');

    // * RTK QUERY - CLEAR CACHE
    // If RTK Query is used for data fetching/caching then this clears cached data.
    // NOTE: This might need to be moved to accountSaga.js
    // Assuming you have a method or action to reset the state of your RTK Query slice
    // For example:
    // yield put(apiSlice.util.resetApiState());

    // * REACT- DELETE TIME META COOKIE
    // Before purging the persistor or clearing the state,
    // delete the "time_meta_cookie".
    yield call(deleteCookie, 'time_meta_cookie', '/', 'localhost');
    console.info('The cookie "time_meta_cookie" has been deleted');

    // * REDUX PERSIST - PURGE PERSISTED STATE
    // Since Redux Persist's stored state might be persisting, it is cleared with this.
    // Since this function manipulates the store directly, it does not require asynchronous
    // handling via call() function.  Note: "For local storage, you can run localStorage.clear()"
    console.info(
      "Redux Persist's local storage state BEFORE purge: ",
      persistor
    );
    // Add a callback to persistor.purge() to ensure it completes. Since persistor.purge()
    // returns a promise, you can use yield to wait for the promise to resolve:
    yield call([persistor, 'purge']);

    console.info(
      "Redux Persist's local storage state AFTER purge: ",
      persistor
    );

    yield put(authActions.signOutSuccess());
    yield put(push('/sign-in'));
    console.info('User successfully signed-out');
  } catch (error) {
    // If try/catch block fails, which can happen for
    // a variety of reasons, log error object to console.
    console.error('Error in "catch" block of signOutSaga.js:', error);
    // The few lines below are an attempt at a more robust error handling
    // strategy to deal with the possibility that error.response is undefined.
    let defaultErrorMessage = 'An unexpected error occurred';
    if (error.response && error.response.data) {
      // HTTP ERRORS
      // Such errors can be the result of bad API request, expired / invalid token,
      // etc.These errors can have two possible response objects, which are typical
      // of HTTP errors thrown by axios: response and response.data. If so, this
      // condition will log to console the error message from respons.data.message.
      defaultErrorMessage =
        error.response.data.message || defaultErrorMessage;
    } else if (error.message) {
      // NON-HTTP ERRORS
      // Such errors can be the result of network problems, JavaScript errors,
      // misconfigured apiClient.js, etc. These error do not have a response object,
      // which is an indication that it's not an HTTP error. If so, it will use the error's
      // message property to display the message.
      defaultErrorMessage = error.message;
    }
    yield put(authActions.signOutFailure(defaultErrorMessage));
    console.info('User failed to sign-out correctly');
  }
}

// The takelatest effect is listining for this specific action type
// that's in the authSlice.
export function* watchSignOutSaga(persistor) {
  yield takeLeading(authActions.signOutStart.type, signOutSaga, persistor);
}
