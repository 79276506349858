// * HELLO - VIEW
// * ------------

// * LIBRARY/FRAMEWORK IMPORTS
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Textarea,
  IconButton,
} from '@material-tailwind/react';
// import { LinkIcon } from '@heroicons/react/24/outline';

// import { useMediaQuery } from '@mui/material';
// * LOCAL IMPORT - HOOKS
// import { button } from '@material-tailwind/react';

import { ErrorHandler } from '../../../../shared/hooks/ErrorHandler';
// * LOCAL IMPORT - AXIOS CLIENT
import {
  useValidateSlugMutation,
  useChatWithAIMutation,
} from '../../../../app/api/apiSlice';
// * LOCAL IMPORT - COMPONENT
import { LocalSpinner } from '../../../../shared/ui/components/LocalSpinner';

export function HelloView() {
  // * RETURN OBJECT FROM URL PARAMETER
  // React Router hook's "useParams" extracts the "slug" variable
  // in the URL paramter and then destructures it to get its value.
  const { slug } = useParams();

  // * DECLARE STATE VARIABLES
  const [messages, setMessages] = useState([]);

  // * DECLARE STATE VARIABLES
  // The "useState" hook declares the follow state variables here:
  // - userInput: holds current value of patient's input (which will be sent to AI)
  // - setUserInput: function that is called to update "userInput" when patient types.
  const [userInput, setUserInput] = useState('');

  // * RTK QUERY HOOK - VALIDATE SLUG
  // Before starting the chat session, the slug must be validated. This will
  // result in the issue of a JWT access token (in HttpOnly cookie) and CSRF
  // token (in non-HttpOnly cookie).
  const [
    validateSlug,
    { isLoading: isSlugLoading, isError: isSlugError, error: slugError },
  ] = useValidateSlugMutation();
  // * RTK QUERY HOOK - SEND MESSAGE
  // After slug validation, communication with OpenAI API will be handled here
  // (through the backend). This will function will send patient requests and
  // receive OpenAI responses.
  const [
    sendMessageToAI,
    { isLoading: isChatLoading, isError: isChatError, error: chatError },
  ] = useChatWithAIMutation();

  // * COMPONENT MOUNT CALL - VALIDATE SLUG
  // When component mounts, call "slug" and "validateSlug".
  useEffect(() => {
    validateSlug(slug);
  }, [slug, validateSlug]);

  // * EVENT HANDLER - SEND MESSAGE
  // When patient clicks the "send message" button, the "sendMessageToAI" mutation hook
  // is used to send the current value of the "userInput" to the backend.
  // const handleSendMessage = () => {
  //   sendMessageToAI({ message: userInput });
  // };

  const handleSendMessage = async () => {
    if (userInput.trim()) {
      const userMessage = {
        id: new Date().getTime(),
        text: userInput,
        sender: 'user',
      };
      // Optimistically add the user message to the chat.
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setUserInput('');

      try {
        // Send the user message to the AI and wait for a response.
        const response = await sendMessageToAI({
          message: userInput,
        }).unwrap();
        // Add the AI response to the chat.
        setMessages(prevMessages => [
          ...prevMessages,
          {
            id: new Date().getTime(),
            text: response.response,
            sender: 'ai',
          },
        ]);
      } catch (error) {
        console.error('Error sending message to AI:', error);
      }
    }
  };

  // * UI LOADING STATE
  // RTK Query's loading state provides more fine-grained control over loading state
  // than the global provider for React Suspense. If slug validation or chat message
  // is still being processed, this indicator will show.
  const isAnyLoading = isSlugLoading || isChatLoading;
  if (isAnyLoading) {
    return <LocalSpinner />;
  }

  // * UI ERROR HANDLER
  // If error object present, this error is thrown (instead of component failing to render).
  // Note: This error can occur during data fetching or any other operation related to the
  // redering of this component.
  const isAnyError = isSlugError || isChatError;
  let displayError;
  if (isSlugError) {
    displayError = slugError; // Error from slug validation
  } else if (isChatError) {
    displayError = chatError; // Error from chat message sending
  }
  if (isAnyError) {
    return <ErrorHandler error={displayError} />;
  }

  // const isMobile = useMediaQuery('(max-width:640px)'); // Adjust the breakpoint as needed
  // if (!isMobile) {
  //   return (
  //     <div>
  //       Please use a smartphone to access this chat feature.
  //     </div>
  //   );
  // }

  // * RENDER COMPONENT
  return (
    <div className="flex flex-col w-full my-4">
      <Card className="flex-grow overflow-auto">
        <CardBody className="flex flex-col gap-4">
          {messages.map(message => (
            <div
              key={message.id}
              className={`flex ${message.sender === 'user' ? 'justify-end' : ''}`}
            >
              <div
                className={`p-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              >
                {message.text}
              </div>
            </div>
          ))}
        </CardBody>
      </Card>

      <div className="mt-4 flex w-full flex-row items-center rounded-lg border bg-gray-400/10 p-2">
        <Textarea
          rows={1}
          noresize
          type="text"
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
          placeholder="Your Message"
          className="min-h-full !border-0 focus:border-transparent text-xl"
          containerProps={{
            className: 'grid h-full',
          }}
          labelProps={{
            className: 'before:content-none after:content-none',
          }}
        />
        <div>
          <IconButton
            variant="text"
            onClick={handleSendMessage}
            fullWidth
            type="button"
            size="lg"
            className="font-extrabold text-blue-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
              />
            </svg>
          </IconButton>
        </div>
      </div>
    </div>

    // <div>
    //   {chatResponse && (
    //     <div className="chat-response">
    //       {chatResponse.response}
    //     </div>
    //   )}
    //   <input
    //     value={userInput}
    //     onChange={e => setUserInput(e.target.value)}
    //   />
    //   <button type="button" onClick={handleSendMessage}>
    //     Send Message
    //   </button>
    // </div>
  );
}
